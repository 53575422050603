import React from "react";
import './styles/Video.css'

const Video = () => (
    <div class="parallax2">
        <video class="videodestaque" autoPlay loop muted playsInline>
            <source src="https://videos.pexels.com/video-files/2058803/2058803-hd_1920_1080_25fps.mp4" type="video/mp4" />
        </video>
        <div className="textoParallax">
            <h2>Conforto</h2>
            <p>Imagine-se no conforto do seu escritório, enquanto uma equipe especializada constrói um IMPÉRIO DIGITAL da sua MARCA e da obra da sua vida! <br/>  <br/> 
            Contratando a SKYZ DESIGN você pode DESFRUTAR DE MOMENTOS  INCRÍVES E ÚNICOS, apenas tendo a oportunidade de não se preocupar com o que está sendo feito nos bastidores.
            <br/> <br/> 
            Que tal entrar em contato conosco?</p>
        </div>
    </div>
);

export default Video;