import './components/styles/App.css'; 
import Header from './components/Header.js';
import Main from './components/Main.js';
import Sobre from './components/Sobre.js';
import Servicos from './components/Servicos.js';
import Video from './components/Video.js'
import Formulario from './components/Formulario.js';
import Contato from './components/Contato.js'
import Footer from './components/Footer.js'
import React, { useEffect } from 'react';
import { addEventListeners } from './components/Script.js';


function App() {

  useEffect(() => {
    const removeEventListeners = addEventListeners();
    return () => {
      removeEventListeners();
    };
  }, []);

  return (
      <div className="App">
        <Header />
        <Main />
        <Sobre />
        <Servicos />
        <Video />
        <Formulario />
        <Contato />
        <Footer />
      </div>
  );
}

export default App;
