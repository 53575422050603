export const addEventListeners = () => {
    const handleScroll = () => {
        const video = document.querySelector('.parallax-video');
        const container = document.querySelector('.parallax-container');
        if (container && video) {
            const scrollPosition = window.pageYOffset;
            const containerOffsetTop = container.offsetTop;
            const containerHeight = container.offsetHeight;

            if (scrollPosition >= containerOffsetTop - window.innerHeight && scrollPosition <= containerOffsetTop + containerHeight) {
                const parallaxOffset = (scrollPosition - containerOffsetTop) * 0.5;
                video.style.transform = `translate(-50%, calc(-50% + ${parallaxOffset}px))`;
            }
        }
    };

    const handleHeaderScroll = () => {
        const header = document.querySelector('.header');
        if (header) {
            if (window.scrollY > 1) {
                header.style.backgroundColor = '#000'; // Cor do cabeçalho ao rolar para baixo
            } else {
                header.style.backgroundColor = '#4CAF5000'; // Cor inicial do cabeçalho
            }
        }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleHeaderScroll);

    // Limpar os event listeners quando o componente for desmontado
    return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('scroll', handleHeaderScroll);
    };
};