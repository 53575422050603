import React, { useEffect, useState } from 'react';
import './styles/Main.css';

const Main = () => {
  const words = ['Inteligente', 'Personalizado', 'Original', 'Dinâmico', 'Intuitivo', 'Moderno', 'Responsivo', 'Criativo', 'Funcional', 'Interativo', 'Inovador', 'Eficiente', 'Elegante'];
  const [currentWord, setCurrentWord] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Inicia o fade out

      setTimeout(() => {
        setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        setFade(true); // Inicia o fade in
      }, 500); // Tempo para o fade out, que deve coincidir com o tempo no CSS
    }, 2500); // Altera a palavra a cada 2.5 segundos (2s de visibilidade + 0.5s de transição)

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, [words.length]);

  useEffect(() => {
    const handleScroll = () => {
      const video = document.querySelector('.videobg');
      const scrollPosition = window.pageYOffset;
      const parallaxOffset = scrollPosition * 0; // Ajuste conforme necessário
      video.style.transform = `translateY(${parallaxOffset}px)`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='parallax'>
      <div className="video-container">
        <video className="videobg" autoPlay loop muted playsInline>
          <source src="https://videos.pexels.com/video-files/4496268/4496268-hd_1920_1080_25fps.mp4" type="video/mp4" />
        </video>
      </div>
      <div className='content'>
        <main>
          <h1>SkyZ Design</h1>
          <p>
            Design{' '}
            <span className={`word-fade ${fade ? 'word-visible' : 'word-hidden'}`}>
              {words[currentWord]}
            </span>{' '}
            para WEBSites e Redes Sociais
          </p>
          <a href="https://api.whatsapp.com/send?phone=5519997013275&text=Ol%C3%A1,%20quero%20um%20or%C3%A7amento!">
            <button id="clique">Entrar em contato!</button>
          </a>
        </main>
      </div>
    </div>
  );
};

export default Main;
