import React from "react";
import './styles/Contato.css';
import Wapp from './wapp.svg';
import Insta from './instagram.svg';
import Face from './facebook.svg';
import Logo from './logo2.png';

const Contato = () => (
    <section id="section2">
        <div className="infos">
            <a href="https://wa.me/send?phone=5519997013275&text=Ol%C3%A1,%20quero%20um%20or%C3%A7amento!" className="logo-icon" target="_blank" rel="noopener noreferrer" title="logoicon">
                <img src={Logo} alt="logo-icon" id="logo-icon"/>
            </a>
            <p>+55 (19) 99701-3275</p>
            <p>contato@skyzdesign.com.br</p>
            <a href="https://wa.me/send?phone=5519997013275&text=Ol%C3%A1,%20quero%20um%20or%C3%A7amento!" className="whatsapp-icon" target="_blank" rel="noopener noreferrer" title="whatsapp">
                <img src={Wapp} alt="WhatsApp" id="whatsapp-icon"/>
            </a>
            <a href="https://www.instagram.com/skyzdesignerbr/" className="instagram-icon" target="_blank" rel="noopener noreferrer" title="instagram">
                <img src={Insta} alt="Instagram" id="instagram-icon"/>
            </a>
            <a href="https://www.facebook.com/skyzdesigner/" className="facebook-icon" target="_blank" rel="noopener noreferrer" title="facebook">
                <img src={Face} alt="Facebook" id="facebook-icon"/>
            </a>
            
        </div>
        <div className="infomap">
            <h2>Localização:</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58801.11080701367!2d-47.060194!3d-22.91081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8c8b741925a85%3A0x7f65542c4ec76958!2sCentro%2C%20Campinas%20-%20SP%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1719549644690!5m2!1spt-BR!2sus" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>
        </div>
    </section>
);

export default Contato;