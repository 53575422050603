import React from 'react';
import './styles/Alerta.css';

const Alerta = ({ mostrar, tipo, mensagem }) => {
  if (!mostrar) {
    return null;
  }

  const alertaClasses = tipo === 'sucesso' ? 'alerta alerta-sucesso' : 'alerta alerta-erro';

  return (
    <div className={alertaClasses}>
      {mensagem}
    </div>
  );
};

export default Alerta;