import React, { useState } from 'react';
import './styles/Formulario.css';
import Alerta from './Alerta.js';
import emailjs from 'emailjs-com';

const Formulario = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [assunto, setAssunto] = useState('');
  const [texto, setTexto] = useState('');
  const [mostrarAlertaSucesso, setMostrarAlertaSucesso] = useState(false);
  const [mostrarAlertaErro, setMostrarAlertaErro] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      from_name: nome,
      to_name: 'ohskyz123@gmail.com', // Altere para o destinatário desejado
      subject: assunto,
      phone: phone,
      message: texto,
      email: email,
    };

    emailjs.send(
      'service_0vskvha', // ID do serviço
      'template_ml3as8a', // ID do template
      templateParams,
      'LNgFuCHuOPRxlj5Z8' // Chave pública
    )
    .then((response) => {
      console.log('Email enviado com sucesso:', response.text);
      setMostrarAlertaSucesso(true); // Mostrar o alerta de sucesso após o envio bem-sucedido
      setMostrarAlertaErro(false); // Ocultar o alerta de erro, se estiver visível
      // Limpar os campos do formulário
      setNome('');
      setEmail('');
      setPhone('');
      setAssunto('');
      setTexto('');
      // Ocultar o alerta após alguns segundos
      setTimeout(() => setMostrarAlertaSucesso(false), 5000);
    })
    .catch((error) => {
      console.error('Erro ao enviar e-mail:', error);
      setMostrarAlertaErro(true); // Mostrar o alerta de erro
      setMostrarAlertaSucesso(false); // Ocultar o alerta de sucesso, se estiver visível
      setTimeout(() => setMostrarAlertaErro(false), 5000);
    });
  };

  return (
    <section id='section3'>
        <div className="formulario">
            <form onSubmit={onSubmit}>
                <h2>Orçamento</h2>
                <label htmlFor="nome">Nome Completo:</label>
                  <input type="text" id="nome" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} required />

                <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

                <label htmlFor="phone">Telefone:</label>
                  <input type="phone" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />

                <label htmlFor="assunto">Assunto:</label>
                  <select id="assunto" name="assunto" value={assunto} onChange={(e) => setAssunto(e.target.value)} required>
                    <option value="Selecione um serviço">Selecione um Serviço:</option>
                    <option value="Webdesign">Webdesign</option>
                    <option value="Gestão de Tráfego Pago">Gestão de Tráfego Pago</option>
                    <option value="SocialMedia">SocialMedia</option>
                    <option value="Design Gráfico - Logotipos e banners">Logotipos e banners</option>
                    <option value="Design Gráfico - Identidade Visual">Logotipos e Identidade Visual</option>
                  </select>

                <label htmlFor="texto">Mensagem:</label>
                  <textarea id="texto" name="texto" value={texto} onChange={(e) => setTexto(e.target.value)} rows="2" required />

                <button type="submit">Enviar</button>
            </form>
         </div>
         <Alerta mostrar={mostrarAlertaSucesso} tipo="sucesso" mensagem="Email enviado com sucesso!" /> {/* Componente Alerta para sucesso */}
         <Alerta mostrar={mostrarAlertaErro} tipo="erro" mensagem="Erro ao enviar o email. Tente novamente." /> {/* Componente Alerta para erro */}
    </section>
  );
};

export default Formulario;