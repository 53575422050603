import React from 'react';
import './styles/Sobre.css';
import logocl from './logocl.png';
import logomiugas from './logomiugas.png';
import logosc from './logosc.png';
import logopw from './LOGO PW.png';
import logoupg from './logoupg.png';
import logoacag from './logoacag.png';
import logoeduv from './logoeduv.png';

const Sobre = () => (
            <section>
                <div class="sobre" id="sobre">
                    <h2>Sobre nós</h2>
                    <p>Somos uma empresa dedicada a transformar ideias em experiências digitais excepcionais. Combinando criatividade e tecnologia, nosso objetivo é criar sites e interfaces gráficas que não apenas impressionem visualmente, mas também proporcionem uma navegação intuitiva e envolvente. <br/> <br/> <hr/> <br/> Nossa missão é ajudar empresas e profissionais a se destacarem no mundo digital, oferecendo soluções de design personalizadas que atendam às suas necessidades específicas. <br/> Acreditamos que um bom design é essencial para o sucesso online e trabalhamos incansavelmente para garantir que cada projeto reflita a identidade e os valores de nossos clientes.</p>
                    <br/><hr/><br/>
                    <a href="#sobre"><button id="clique1">Conheça nossos clientes!</button></a>
                    <div class='Clientes' id='Clientes'>
                        <div className="Clientes-wrapper">
                            <br/><br/><hr/><br/>
                                <a href='https://www.academiagirassol.com.br' target='__blank'><img alt='Academia de Beleza - Girassol' src={logoacag} id='logogirassol'/></a>
                                <a href='https://www.instagram.com/duneeves/' target='__blank'><img alt='Edu Veículos - Loja Virtual de veículos' src={logoeduv} id='logoeduv'/></a>
                                <a href='https://www.instagram.com/crie.lacosatelie/' target='__blank'><img alt='CrieLaços - Conviteria' src={logocl} id='logocrielacos'/></a>
                                <a href='https://www.migueliugas.com.br' target='__blank'><img alt='Miguel Iugas - Farmaceutico' src={logomiugas} id='logomiguel'/></a>
                                <a href='https://www.instagram.com/provadorwear/'><img alt='Provador Wear Store' src={logopw} id='logopw'/></a>
                                <a href='https://www.upgloss.com.br'><img alt='UpGloss' src={logoupg} id='logoupg'/></a>
                                <a href='//'><img alt='StreetClub Store' src={logosc} id='logosc'/></a>
                                {/* extra for looping */}
                                <a href='https://www.academiagirassol.com.br' target='__blank'><img alt='Academia de Beleza - Girassol' src={logoacag} id='logogirassol'/></a>
                                <a href='https://www.instagram.com/duneeves/' target='__blank'><img alt='Edu Veículos - Loja Virtual de veículos' src={logoeduv} id='logoeduv'/></a>
                                <a href='https://www.instagram.com/crie.lacosatelie/' target='__blank'><img alt='CrieLaços - Conviteria' src={logocl} id='logocrielacos'/></a>
                                <a href='https://www.migueliugas.com.br' target='__blank'><img alt='Miguel Iugas - Farmaceutico' src={logomiugas} id='logomiguel'/></a>
                                <a href='https://www.instagram.com/provadorwear/'><img alt='Provador Wear Store' src={logopw} id='logopw'/></a>
                                <a href='https://www.upgloss.com.br'><img alt='UpGloss' src={logoupg} id='logoupg'/></a>
                                <a href='//'><img alt='StreetClub Store' src={logosc} id='logosc'/></a>
                                <hr/>
                        </div>
                    </div>
                </div>
            </section>
);

export default Sobre;
 
 